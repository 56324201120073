import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const DownLevel = () => {

    const [username, setUsername] = useState('');
    const [level, setLevel] = useState('');

    return (

        <div className='down-level '>
            <div className="page_info"><div className="coustom_container"><div className="d-flex align-items-center justify-content-center"><h6> <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="NewspaperIcon"><path d="m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"></path></svg> Business</h6><div className="inner_links_header"><a href="#" className="d-flex"> <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium me-2 css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="HomeTwoToneIcon"><path d="M12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3zm5 15h-2v-6H9v6H7v-7.81l5-4.5 5 4.5V18z"></path><path d="M7 10.19V18h2v-6h6v6h2v-7.81l-5-4.5z" opacity=".3"></path></svg> Home</a></div></div></div></div>


            <div className='coustom_container'>
                <Form className="form card-view-11">
                    <Row className='edit-user'>
                        <Col xl={12}>
                            <div className="input-div">
                                <label>Enter Username</label>
                                <input type='text' className='form-control' placeholder='Enter Username' onChange={(e: any) => setUsername(e.target.value)} value={username} />
                            </div>
                        </Col>
                        <Col xl={12}>
                            <div className="input-div">
                                <label>Select Level</label><br />

                                <select className='form-control select-input' onChange={(e:any) => setLevel(e.target.value)}>
                                    <option >Select Level</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    {/* <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option> */}
                                </select>
                            </div>
                        </Col>

                        <div className='button-div text-center mt-3'>
                            <Link to={`/down-level-business-report/${username}/${level}`} className='main_button'>Submit</Link>
                        </div>
                    </Row>

                </Form>
            </div>
        </div>
    )
}

export default DownLevel