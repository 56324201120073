import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
// import dateFormat, { DateFormatI18n, DateFormatMasks, formatTimezone, i18n, masks } from "dateformat";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { Link } from "react-router-dom";

const AchieverUsersList = () => {

  const [allDetail, setAllDetail] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const[totalRecords, setTotalRecords] = useState();
  const [itemOffset, setItemOffset] = useState(1);
  const [keyCount, setKeycount] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    const Data = await _fetch(`${api_url}report/ActiveUsersBusiness`, 'GET', {});

    if (Data?.status === "success") {
      setLoading(false);
      setAllDetail(Data?.data);
      setTotalRecords(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  return (
    <>
      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">
          <div className="page_info">
            <div className="coustom_container">
              <div className="d-flex align-items-center justify-content-center">

                <h6> <PersonRounded /> Achiever Users </h6>
                <div className="inner_links_header">

                  <FiberManualRecord className="dot" />
                  <a href="#">Home</a>
                </div>
              </div>
            </div>
          </div>

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Achievers
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> {totalRecords}</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>Almaty Vista force Summit 2024 Achiever List</h1>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Bussiness</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      allDetail?.map((val: any, key: any) => {
                        return (
                          <tr>
                              <td>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>{val?._id?.name}</td>
                            <td>{val?.totalPackage}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {/* {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchieverUsersList;
