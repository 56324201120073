import React, { useEffect, useState } from "react";
import {
  HomeTwoTone,
  FiberManualRecord,
  AttachMoneyRounded,
  PersonRounded,
  SavingsRounded,
  AccountBalanceRounded,
  HistoryEduRounded,
  PersonAdd,
} from "@mui/icons-material";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import checklist from "../Assets/images/checklist.svg";
import chart_blue from "../Assets/images/chart-blue.png";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import tr_img from "../Assets/images/payout-transaction3.png";
import _fetch from "../config/api";
import { Chart } from "react-google-charts";
import { api_url } from "../config/config";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LoadingBar from 'react-top-loading-bar'
import userIconsvg from "../Assets/images/user.png";
import dateformat from "dateformat";

const OverView = (props: any) => {

  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);

  // 1 line chart
  const initialState = {
    series: [
      {
        name: "Desktops",
        data: [10, 41, 35, 51],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        border: 0,
        height: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Product Trends by Month",
        align: "left",
      },
      grid: {
        row: {
          // colors: ["#f3f3f3", "transparent"],
          // opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr"],
      },
    },
  };
  const [chartOptions, setChartOptions] = useState(initialState);
  // useEffect(() => { }, []);

  // 2 area chart
  const areachart = {
    series: [
      {
        name: "Desktops",
        data: [10, 41, 35, 51],
      },
    ],
    options: {
      chart: {
        type: "area",
        zoom: {
          enabled: false,
        },
        border: 0,
        height: "100%",
      },
      colors: ["#2b3892"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Product Trends by Month",
        align: "left",
      },
      grid: {
        row: {
          // colors: ["#f3f3f3", "transparent"],
          // opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr"],
      },
    },
  };
  const [chartarea, setChartarea] = useState(areachart);
  // useEffect(() => { }, []);

  const [allData, setAllData] = useState({ staking_incentive: 0, direct_business_incentive: 0, level_business_incentive: 0, royality_incentive: 0, permanent_reward: 0, difference_business_incentive: 0, totalIncome: "", TotalDeposit: "", allUsers: "", activeUsers: "", inactiveUsers: "", blockUsers: "", avaliableIncome: "", AvaliableDeposit: "", directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", royaltyIncome: "" });
  const [incomeTrxData, setIncomeTrxData] = useState([]);
  const [newJoinersData, setNewJoinersData] = useState([]);
  const [allYesIncome, setAllYesIncome] = useState([]);
  
  const [allTodayStakeBusiness, setAllTodayStakeBusiness] = useState({ todayStakeBusiness: 0, todayUnStakeBuniess: 0, totalStakeBusniess: 0 });

  const [allIncomes, setAllIncomes] = useState({ totalIncome: '', AvaliableDeposit: "", TotalDeposit: '', TotalRebuyDeposit: '', avaliableIncome: '', difference_business_incentive: "", direct_business_incentive: '', level_business_incentive: "", permanent_reward: '', royality_incentive: '', staking_incentive: "", });

  useEffect(() => {
    getHomeData();
    incomeDetail();
    dateincomeCheck();
  }, []);

  const getHomeData = async () => {

    setProgress(10);
    setIsTrue(true);
    // setLoading(true);
    let res = await _fetch(`${api_url}home/dashboard`, "GET", {});
    let res2 = await _fetch(`${api_url}home/today-stake-busniess`, "GET", {});
    if (res?.status === "success") {
      setAllData(res?.data);
      setNewJoinersData(res?.data?.newJoinings);
      setIncomeTrxData(res?.data?.incomeTransactions);
      setProgress(100);
      setIsTrue(false);
    }
    if (res2?.status === "success") {
      
      setAllTodayStakeBusiness(res2?.data);
      setProgress(100);
      setIsTrue(false);
    }
  }

  const incomeDetail = async () => {
    let data = await _fetch(`${api_url}home/income-details`, "GET", {}, {});
    if (data?.status === 'success') {
      setAllIncomes(data?.data);

    }
  }


  const dateincomeCheck = async () => {
    let data = await _fetch(`${api_url}report/todaytypesincome`, "GET", {}, {});
    console.log('data', data);
    
    if (data?.status === 'success') {
      setAllYesIncome(data?.data);
    }
  }

  return (
    <>
      <div className="main_class">

        {/* 
      {loading && (
       <> */}
        <LoadingBar
          color='var(--primary-color)'
          progress={progress}
          containerClassName="LoadingBar_contain"
          className="loaderbar-s"
          onLoaderFinished={() => setProgress(0)}
        />

        <div className={isTrue ? 'loader-bg' : ''}></div>
        {/* </>
       )
       } */}




        <div className="page_info">
          <div className="coustom_container">
            <div className="d-flex align-items-center justify-content-center">

              <h6> <HomeTwoTone /> {props.pageTitle}</h6>
              <div className="inner_links_header">

                <FiberManualRecord className="dot" />
                <a href="#">Analytics</a>
              </div>
            </div>
          </div>
        </div>

        <div className="home-sec pb-5">
          <div className="coustom_container">
            <div className="row mt-5 main-row">

              <div className="col-lg-6 column-sec">
                <div className="card first-card">
                  <div className="card-header justify-content-start">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <PersonRounded className="icon user" />
                    </div>
                    <div className="card-ryt-text text-left d-flex align-items-center">
                      <h4 className="text-left me-2 mb-0">{allData?.allUsers}</h4>
                      <p>All Participants</p>
                    </div>
                  </div>

                  <div className="card-body ">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="card-sec-1">
                          <p className="text-success">Active Participants <br /> <span>{allData?.activeUsers}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="card-sec-1">
                          <p className="text-warning">Inactive Participants <br /> <span>{allData?.inactiveUsers}</span></p>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="card-sec-1">
                          <p className="text-danger">Block Participants <br /> <span> {/* {allData?.blockUsers} */} 0</span></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    {/* <img src={chart_blue} alt="blue-chart" width="100%" height="100%" /> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 column-sec">
                <div className="row" style={{ rowGap: '20px' }}>
                  <div className="col-6 column-sec">
                    <div className="card first-card">
                      <div className="card-header">
                        <div className="card-imgs">
                          <img src={card_1} alt="icon" />
                          <AttachMoneyRounded className="icon payout" />
                        </div>
                        <div className="card-ryt-text">
                          <h4>${Number(allIncomes?.totalIncome)?.toFixed(2)}</h4>
                          <p>Total Payout</p>
                        </div>
                      </div>

                      <div className="card-footer">
                        <img src={chart_blue} alt="blue-chart" width="100%" height="100%" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-6 column-sec">
                    <div className="card first-card">
                      <div className="card-header">
                        <div className="card-imgs">
                          <img src={card_1} alt="icon" />
                          <SavingsRounded className="icon deposit" />
                        </div>
                        <div className="card-ryt-text">
                          <h4>${Number(allIncomes?.TotalDeposit)?.toFixed(2)}</h4>
                          <p>Total Deposit</p>
                        </div>
                      </div>

                      <div className="card-footer">
                        <img src={chart_blue} alt="blue-chart" width="100%" height="100%" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>



              {/* extra card's */}
              <div className="col-lg-4 column-sec">
                  <div className="card first-card">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon" />
                        <AttachMoneyRounded className="icon payout" />
                      </div>
                      <div className="card-ryt-text">
                        <h4>${Number(allTodayStakeBusiness?.todayStakeBusiness)?.toFixed(2)}</h4>
                        <p>Today Stake Business</p>
                      </div>
                    </div>

                    <div className="card-footer">
                      <img src={chart_blue} alt="blue-chart" width="100%" height="100%" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 column-sec">
                  <div className="card first-card">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon" />
                        <AttachMoneyRounded className="icon payout" />
                      </div>
                      <div className="card-ryt-text">
                        <h4>${Number(allTodayStakeBusiness?.todayUnStakeBuniess)?.toFixed(2)}</h4>
                        <p>Today Re-Stake Business</p>
                      </div>
                    </div>

                    <div className="card-footer">
                      <img src={chart_blue} alt="blue-chart" width="100%" height="100%" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 column-sec">
                  <div className="card first-card">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon" />
                        <AttachMoneyRounded className="icon payout" />
                      </div>
                      <div className="card-ryt-text">
                        <h4>${Number(allTodayStakeBusiness?.totalStakeBusniess)?.toFixed(2)}</h4>
                        <p>Today Total Business </p>
                      </div>
                    </div>

                    <div className="card-footer">
                      <img src={chart_blue} alt="blue-chart" width="100%" height="100%" />
                    </div>
                  </div>
                </div>

              {/*  starting card's */}

              <div className="col-md-6">
                <div className="card income-card">
                  <div className="heading-income-card">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AccountBalanceRounded className="icon payout" />
                    </div>

                    <h5 className="mb-0 ms-2" >Income details</h5>
                  </div>

                  <Tabs>
                    {/* <div className="card-header">
                      <TabList>
                        <Tab>Income</Tab>
                        <Tab>Title 2</Tab>
                      </TabList>
                    </div> */}

                    <div className="card-body">
                      <TabPanel>
                        <div className="income-tabs-card">
                          <div className="tabs-table-sec">
                            <p> <span className="dots"></span> Direct Business Incentive</p>
                            <p className="income-balance">${Number(allIncomes?.direct_business_incentive)?.toFixed(2)}</p>
                          </div>
                          <div className="tabs-table-sec">
                            <p>  <span className="dots"></span>Level Business Incentive</p>
                            <p className="income-balance">${Number(allIncomes?.level_business_incentive)?.toFixed(2)}</p>
                          </div>

                          <div className="tabs-table-sec">
                            <p>  <span className="dots"></span> Royality Incentive</p>
                            <p className="income-balance">${Number(allIncomes?.royality_incentive)?.toFixed(2)}</p>
                          </div>

                          <div className="tabs-table-sec">
                            <p>  <span className="dots"></span> Permanent Reward</p>
                            <p className="income-balance">${Number(allIncomes?.permanent_reward)?.toFixed(2)}</p>
                          </div>

                          <div className="tabs-table-sec">
                            <p>  <span className="dots"></span> Difference Business Incentive</p>
                            <p className="income-balance">${Number(allIncomes?.difference_business_incentive)?.toFixed(2)}</p>
                          </div>
                          <div className="tabs-table-sec">
                            <p>  <span className="dots"></span> Staking Incentive</p>
                            <p className="income-balance">${Number(allIncomes?.staking_incentive)?.toFixed(2)}</p>
                          </div>

                        </div>
                      </TabPanel>
                      {/* <TabPanel>
                      <h2>Any content 2</h2>
                    </TabPanel> */}
                    </div>
                  </Tabs>


                </div>
              </div>

              <div className="col-md-6">
                <div className="card adduser-card">
                  <div className="heading-adduser-card">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <PersonAdd className="icon payout" />
                    </div>

                    <h5 className="mb-0 ms-2" >New Members</h5>
                  </div>

                  <div className="card-body">
                    <div className="adduser-tabs-card">
                      {newJoinersData?.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="tabs-adduser-sec">
                              <div className="tr-left">
                                <div className="tr-img">
                                  <img src={userIconsvg} alt="img" />
                                </div>
                                <div className="tr-text">
                                  <h5>{item?.user_info?.name != 'null null' ? item?.user_info?.name : '--'}</h5>
                                  <p>{item?.username}</p>
                                </div>
                              </div>

                              <div className="tr-right">
                                <p>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</p>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>


                </div>
              </div>

              <div className="col-12">
                <div className="card transactions-card">
                  <div className="heading-transactions-card">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <HistoryEduRounded className="icon payout" />
                    </div>

                    <h5 className="mb-0 ms-2" >Transactions History</h5>
                  </div>

                  <div className="card-body">
                    <div className="transactions-tabs-card">
                      <table className="table">
                        <tbody>
                          {incomeTrxData?.map((item: any, index: any) => {
                            return (
                              <>


                                <tr>
                                  <td>
                                    <div className="tr-img">
                                      <img src={tr_img} alt="tr-img" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tr-text">
                                      {/* <h5>Payout</h5> */}
                                      <p>{item?.username}</p>
                                    </div>
                                  </td>
                                  <td className="blanace">${item?.amount?.toFixed(8)}</td>
                                  {/* <td> <span className="badge-span debit">Debit</span> </td> */}
                                  {/* <td className="badge-status"> <span className="badge-span credit">{item?.description}</span> </td> */}
                                  <td className="date">{item?.description}</td>
                                  <td className="date">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                </tr>
                              </>
                            )
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>


                </div>
              </div>




              <div className="col-12">
                <div className="card transactions-card">
                  <div className="heading-transactions-card">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <HistoryEduRounded className="icon payout" />
                    </div>

                    <h5 className="mb-0 ms-2" >Yesterday History</h5>
                  </div>

                  <div className="card-body">
                    <div className="transactions-tabs-card">
                      <table className="table">
                        <tbody>
                          {allYesIncome?.map((item: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="tr-img">
                                      <img src={tr_img} alt="tr-img" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tr-text">
                                      <p>{item?.type}</p>
                                    </div>
                                  </td>
                                  <td className="blanace">${item?.totalIncome?.toFixed(8)}</td>
                                </tr>
                              </>
                            )
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>


                </div>
              </div>



            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default OverView;
